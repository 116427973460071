import { extend, localize } from 'vee-validate'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import {
  validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard,
} from '@/@core/utils/validations/validators'

const customRules = {
  'youtube-url': value => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/
    if (regex.test(value)) return true
    return 'This Link isn\'t a valid Youtube Url'
  },

  'facebook-url': value => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\\.]*\/)*([\w\-\\.]*)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Facebook Url'
  },

  'twitter-url': value => {
    const regex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Twitter Url'
  },

  'instagram-url': value => {
    const regex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\\.]+)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Instagram Url'
  },

  'max-dimension': {
    params: ['imageWidth', 'imageHeight'],
    message: 'The {_field_} must be UP TO {imageWidth} pixels by {imageHeight} pixels',

    validate: (imageFile, { imageWidth, imageHeight }) => {
      const promise = new Promise(resolve => {
        const image = new Image()
        const reader = new FileReader()
        reader.onload = e => {
          image.src = e.currentTarget.result
          if (image.width < imageWidth && image.height < imageHeight) resolve(true)
          resolve(false)
        }
        reader.readAsDataURL(imageFile)
      })
      return promise.then(res => res)
    },
  },

  'image-aspect-ratio': {
    params: ['imageWidth', 'imageHeight'],
    message: 'The {_field_} ratio must be {imageWidth}:{imageHeight}',

    validate: (imageFile, { imageWidth, imageHeight }) => {
      const promise = new Promise(resolve => {
        const image = new Image()
        const reader = new FileReader()
        reader.onload = e => {
          image.src = e.currentTarget.result
          const { width, height } = image
          const actualRatio = Math.floor(width / height)
          const specificRatio = Math.floor(imageWidth / imageHeight)
          if (actualRatio === specificRatio) resolve(true)
          resolve(false)
        }
        reader.readAsDataURL(imageFile)
      })
      return promise.then(res => res)
    },
  },

  positive: {
    validate: validatorPositive,
    message: 'Please enter positive number!',
  },

  'credit-card': {
    validate: validatorCreditCard,
    message: 'It is not valid credit card!',
  },

  password: {
    validate: validatorPassword,
    message: 'Your {_field_} minimum length 8 and should contain at least one uppercase, one lowercase, one special character and one digit',
  },

  url: {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
  },
}

const allRules = { ...customRules, ...rules }

// for every rule we defined above.
Object.keys(allRules).forEach(rule => {
  //  add the rule.
  extend(rule, allRules[rule])
})

localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})

import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export default function signOut() {
  const removeUserData = () => {
    store.commit('auth/setUser', '')
    localStorage.removeItem('userData')
  }
  const removeAccessToken = () => {
    localStorage.removeItem('accessToken')
  }
  const removePermissions = () => {
    const guestPermissions = [{
      subject: 'Auth',
      action: 'read',
    }]

    Vue.prototype.$ability.update(guestPermissions)
    localStorage.removeItem('abilities')
  }
  const removeMainEntityFromStore = () => {
    store.commit('mainEntity/setEntityId', '')
    store.commit('mainEntity/setEntityName', 'No Entity')
  }

  const removeMainEntityFromLocalStorage = () => {
    localStorage.removeItem('entityId')
    localStorage.removeItem('entityName')
  }

  const logout = () => {
    removeUserData()
    removeAccessToken()
    removePermissions()
    removeMainEntityFromStore()
    removeMainEntityFromLocalStorage()
    router.push('/login')
  }

  return {
    logout,
  }
}

export default [
  {
    path: '/organization-dash/prayers',
    name: 'prayers-time-list',
    component: () => import('@/views/prayers/PrayersList.vue'),
    meta: {
      resource: 'prayers',
      action: 'viewPrayers',
    },
  },
  {
    path: '/organization-dash/get-azan',
    name: 'get-azan',
    component: () => import('@/views/prayers/getAzan.vue'),
    meta: {
      resource: 'prayers',
      action: 'getAzan',
    },
  },
  {
    path: '/organization-dash/update-iqama',
    name: 'update-iqama',
    component: () => import('@/views/prayers/UpdateIqama.vue'),
    meta: {
      resource: 'prayers',
      action: 'viewUpdateIqama',
    },
  },
  {
    path: '/organization-dash/import-export',
    name: 'import-export',
    component: () => import('@/views/prayers/PrayerImportExport.vue'),
    meta: {
      resource: 'prayers',
      action: 'importAndExport',
    },
  },
]

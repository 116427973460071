export default [
  {
    path: '/live-management/list',
    name: 'live-management-list',
    component: () => import('@/views/live-management/list.vue'),
    meta: {
      resource: 'LiveController',
      action: 'show',
    },
  },
  {
    path: '/live-management/add',
    name: 'add-live',
    component: () => import('@/views/live-management/control-live/addLive.vue'),
    meta: {
      resource: 'LiveController',
      action: 'store',
    },
  },
  {
    path: '/live-management/edit/:id',
    name: 'edit-live',
    component: () => import('@/views/live-management/control-live/editLive.vue'),
    meta: {
      resource: 'LiveController',
      action: 'update',
    },
  },
]

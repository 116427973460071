export default [
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/dashboard/adminDashboard.vue'),
    meta: {
      resource: 'admin-dashboard',
      action: 'read',
    },
  },
  {
    path: '/inpo-dashboard',
    name: 'inpo-dashboard',
    component: () => import('@/views/dashboard/inpoDashboard.vue'),
    meta: {
      resource: 'DashboardController',
      action: 'get-dashboard-statistics',
    },
  },
]

export default [
  {
    path: '/announcements/list',
    name: 'announcements-list',
    component: () => import('@/views/announcements/list.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'show',
    },
  },
  {
    path: '/announcements/add',
    name: 'add-announce',
    component: () => import('@/views/announcements/control-announcements/addAnnouncement.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'store',
    },
  },
  {
    path: '/announcements/view/:id',
    name: 'view-announce',
    component: () => import('@/views/announcements/view.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'show',
    },
  },
  {
    path: '/announcements/edit/:id',
    name: 'edit-announce',
    component: () => import('@/views/announcements/control-announcements/editAnnouncement.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'update',
    },
  },
]

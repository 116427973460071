export default {
  namespaced: true,
  state: {
    entityId: JSON.parse(localStorage.getItem('entityId')) ?? '',
    entityName: localStorage.getItem('entityName') ?? 'No Entity',
  },
  getters: {
    getEntityId(state) {
      return state.entityId
    },
    getEntityName(state) {
      return state.entityName
    },
  },
  mutations: {
    setEntityId(state, id) {
      state.entityId = id
    },
    setEntityName(state, name) {
      state.entityName = name
    },
  },
}

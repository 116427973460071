import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    languages: [],
    tags: [],
    donationCategories: [],
    classifications: [],
    subClassifications: [],
  },
  getters: {
    getLanguages(state) {
      return state.languages
    },
    getTags(state) {
      return state.tags
    },
    getDonationCategories(state) {
      return state.donationCategories
    },
    getActiveDonationCategories(state) {
      return state.donationCategories.filter(category => category.status === 1)
    },
    getClassifications(state) {
      return state.classifications
    },
    getSubClassifications(state) {
      return state.subClassifications
    },
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages
    },
    setTags(state, tags) {
      state.tags = tags
    },
    setDonationCategories(state, donationCategories) {
      state.donationCategories = donationCategories
    },
    setClass(state, classifications) {
      state.classifications = classifications
    },
    setSubClass(state, subClass) {
      state.subClassifications = subClass
    },
  },
  actions: {
    getTagsRequest({ commit }) {
      Vue.prototype.$entities.get('internalops/commercial_entity/getTags')
        .then(response => {
          commit('setTags', response.data)
        })
    },
    getLanguagesRequest({ commit }) {
      Vue.prototype.$entities.get('internalops/languages').then(response => {
        commit('setLanguages', response.data.data.data)
      })
    },
    getClassificationRequest({ commit }) {
      Vue.prototype.$entities
        .get('internalops/classifications')
        .then(response => {
          commit('setClass', response.data.data.data)
        })
    },
    getAllSubClassificationRequest({ commit }) {
      Vue.prototype.$entities
        .get('internalops/sub-classifications')
        .then(response => {
          commit('setSubClass', response.data.data.data)
        })
    },
    getClassSubClassificationRequest({ commit }, id) {
      Vue.prototype.$entities
        .get(`internalops/commercial_entity/getSubClassifications/${id}`)
        .then(response => {
          commit('setSubClass', response.data)
        })
    },
    getDonationCategoriesRequest({ commit }) {
      Vue.prototype.$entities.get('internalops/commercial_entity/getDonationCategories')
        .then(response => {
          commit('setDonationCategories', response.data)
        })
    },
  },
}

export default [
  {
    path: '/mobile-updates/add',
    name: 'add-mobile-update',
    component: () => import('@/views/mobile-updates/edit.vue'),
    meta: {
      action: 'mobile-updates',
      resource: 'add',
    },
  },
]

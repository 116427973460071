export default [
  {
    path: '/inpos/list',
    name: 'inpos-list',
    component: () => import('@/views/inpos/list.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'index',
    },
  },
  {
    path: '/inpos/add',
    name: 'add-inpo',
    component: () => import('@/views/inpos/control-inpos/addInpo.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'store',
    },
  },
  {
    path: '/inpos/edit/:id',
    name: 'inpo-edit',
    component: () => import('@/views/inpos/control-inpos/editInpo.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'update',
    },
  },
  {
    path: '/inpos/view/:id',
    name: 'view-inpo',
    component: () => import('@/views/inpos/view.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'show',
    },
  },
]

import axiosInstances from '@/libs/axios-instances'

export default function userRole() {
  const isUserAdminPromise = () => new Promise((resolve, reject) => {
    axiosInstances.portalUsers.get('v1/admin/check').then(res => {
      if (res.data.meta.message === 'true') resolve()
      else reject()
    })
  })

  return {
    isUserAdminPromise,
  }
}

import Vue from 'vue'
import OrganizationAdminDefaultPermissions from '@/common/defaultPermissions/inpoAdminDefaultPermissions'
import SuperAdminDefaultPermissions from '@/common/defaultPermissions/superAdminDefaultPermissions'
import { setEncryptedItem } from '@/libs/secure-web-storage'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('userData')) ?? null,
    token: localStorage.getItem('accessToken') ?? null,
    isSuperAdmin: false,
    loginData: {
      superAdminLoginData: {
        loginEndPoint: 'admin/login',
        OAuthData: {
          client_id: '3',
          client_secret: 'jo5Tijg53UPNwAUtpypH2qAQkaZX11rD9sbxVsX6',
          grant_type: 'password',
        },
      },
      organizationAdminLoginData: {
        loginEndPoint: 'login',
        OAuthData: {
          client_id: '2',
          client_secret: '5mq3dfH8f21u7J6nMxgCKW06ZiC54uadjYavnqY8',
          grant_type: 'password',
        },
      },
    },
  },
  getters: {
    getOAuthData: state => userType => state.loginData[userType].OAuthData,
    getLoginEndPoint: state => userType => state.loginData[userType].loginEndPoint,
    isLoggedIn(state) {
      return !!state.token
    },
    getUserData(state) {
      return state.user
    },
    getIsSuperAdmin(state) {
      return state.isSuperAdmin
    },
    getAccessToken(state) {
      return state.token
    },
  },
  mutations: {
    setIsSuperAdmin(state, isSuperAdmin) {
      state.isSuperAdmin = isSuperAdmin
    },
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      state.token = token
    },
  },
  actions: {
    login({ commit, getters, dispatch }, userCredentials) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$portalUsers.post(`v1/${getters.getLoginEndPoint(userCredentials.selectedUserType)}`,
          {
            username: userCredentials.userEmail,
            password: userCredentials.password,
            ...getters.getOAuthData(userCredentials.selectedUserType),
          })
          .then(resp => {
            const token = resp.data.data.access_token
            localStorage.setItem('accessToken', token)
            commit('setToken', token)

            dispatch('applyPermissions')

            dispatch('setUserInfoRequest')

            resolve(resp)
          })
          .catch(err => {
            localStorage.removeItem('accessToken')
            reject(err)
          })
      })
    },
    setUserInfoRequest({ commit, getters }) {
      if (!getters.getIsSuperAdmin) {
        Vue.prototype.$portalUsers.get('v1/user').then(res => {
          const userData = res.data.data
          commit('setUser', userData)
          localStorage.setItem('userData', JSON.stringify(userData))
        })
      }
    },
    applyPermissions({ getters }) {
      if (getters.getIsSuperAdmin) {
        Vue.prototype.$ability.update(SuperAdminDefaultPermissions)
        setEncryptedItem('abilities', SuperAdminDefaultPermissions)
      } else {
        Vue.prototype.$ability.update(OrganizationAdminDefaultPermissions)
        setEncryptedItem('abilities', OrganizationAdminDefaultPermissions)
      }
    },
  },
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

import portalUsers from './routes/portal-users'
import mobileUsers from './routes/mobile-users'
import auth from './routes/auth'
import dashboard from './routes/dashboard'
import inpo from './routes/inpo'
import business from '@/router/routes/business'
import announcements from '@/router/routes/announcements'
import liveManagement from '@/router/routes/live-management'
import prayerBookings from '@/router/routes/prayer-booking'
import prayers from '@/router/routes/prayers'
import generalSettings from '@/router/routes/general-settings'
import notifications from '@/router/routes/notifications'
import permissions from '@/router/routes/permissions'
import attendance from '@/router/routes/attendance'
import transactions from '@/router/routes/transactions'
import organizationDashboard from '@/router/routes/organization-dashboard'
import mobileUpdates from '@/router/routes/mobile-updates'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Entity',
        action: 'select-entity',
      },
    },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
    },
    ...portalUsers,
    ...mobileUsers,
    ...auth,
    ...dashboard,
    ...inpo,
    ...business,
    ...announcements,
    ...liveManagement,
    ...prayerBookings,
    ...prayers,
    ...generalSettings,
    ...notifications,
    ...permissions,
    ...attendance,
    ...transactions,
    ...organizationDashboard,
    ...mobileUpdates,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router

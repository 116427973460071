export default [
  {
    path: '/business/list',
    name: 'business-list',
    component: () => import('@/views/business/list.vue'),
    meta: {
      resource: 'CommercialController',
      action: 'search',
    },
  },
  {
    path: '/business/add',
    name: 'add-business',
    component: () => import('@/views/business/control-business/addBusiness.vue'),
    meta: {
      resource: 'CommercialController',
      action: 'store',
    },
  },
  {
    path: '/business/edit/:id',
    name: 'edit-business',
    component: () => import('@/views/business/control-business/editBusiness.vue'),
    meta: {
      resource: 'CommercialController',
      action: 'update',
    },
  },
  {
    path: '/business/view/:id',
    name: 'view-business',
    component: () => import('@/views/business/view.vue'),
    meta: {
      resource: 'CommercialController',
      action: 'show',
    },
  },
]

import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { getEncryptedItem } from '@/libs/secure-web-storage'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const userAbilities = getEncryptedItem('abilities')

export default new Ability(userAbilities || initialAbility)

export default [
  {
    path: '/transactions/list',
    name: 'transactions-list',
    component: () => import('@/views/transactions/list.vue'),
    meta: {
      resource: 'TransactionController',
      action: 'index',
    },
  },
  {
    path: '/donations/list',
    name: 'donations-list',
    component: () => import('@/views/donation-management/list.vue'),
    meta: {
      resource: 'UsersController',
      action: 'getUserDonationForEntity',
    },
  },
  {
    path: '/donations/add-donor',
    name: 'add-donor',
    component: () => import('@/views/donation-management/add-donor.vue'),
    meta: {
      resource: 'UsersController',
      action: 'addDonor',
    },
  },
  {
    path: '/donations/add-donation/:user_id',
    name: 'add-donation',
    component: () => import('@/views/donation-management/add-donation.vue'),
    meta: {
      resource: 'TransactionController',
      action: 'addDonationTransaction',
    },
  },
]

import Vue from 'vue'
import OrganizationAdminDefaultPermissions from '@/common/defaultPermissions/inpoAdminDefaultPermissions'
import superAdminDefaultPermissions from '@/common/defaultPermissions/superAdminDefaultPermissions'
import checkUserIsAdmin from '@/common/compositions/common/checkUserIsAdmin'
import store from '@/store'
import axiosInstances from '@/libs/axios-instances'
import { setEncryptedItem } from '@/libs/secure-web-storage'

export default function permission() {
  const formatPermissions = permissions => {
    const formattedPermissions = []
    permissions.forEach(ele => {
      const [subject, action] = ele.title.split('.')
      formattedPermissions.push({ subject, action })
    })
    return formattedPermissions
  }

  const getSelectedInpoPermissions = entityId => {
    const { username } = store.getters['auth/getUserData'] ?? ''

    axiosInstances.portalUsers.get(`v1/permissions/${username}/${entityId}`).then(res => {
      const newPermissions = res.data.data.permissions
      const formattedPermissions = formatPermissions(newPermissions)
      const allPermissions = [...OrganizationAdminDefaultPermissions, ...formattedPermissions]

      Vue.prototype.$ability.update(allPermissions)

      setEncryptedItem('abilities', allPermissions)
    }).catch(() => {
      Vue.prototype.$ability.update(OrganizationAdminDefaultPermissions)

      setEncryptedItem('abilities', OrganizationAdminDefaultPermissions)
    })
  }

  const setSuperAdminPermissions = () => {
    Vue.prototype.$ability.update(superAdminDefaultPermissions)

    setEncryptedItem('abilities', superAdminDefaultPermissions)
  }

  const { isUserAdminPromise } = checkUserIsAdmin()

  const checkIsUserAdminAndGetSelectedInpoPermissionsPromise = entityId => {
    isUserAdminPromise().then(() => {
      setSuperAdminPermissions()
    }).catch(() => {
      if (entityId) { getSelectedInpoPermissions(entityId) }
    })
  }

  return {
    checkIsUserAdminAndGetSelectedInpoPermissionsPromise,
  }
}

export default [
  {
    path: '/organization-dash/',
    name: 'organization-dash',
    component: () => import('@/views/organization-dashboard/view.vue'),
    meta: {
      resource: 'organization',
      action: 'read',
    },
  },
]

export default [
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/general-settings/Tags.vue'),
    meta: {
      resource: 'tags',
      action: 'control',
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/general-settings/Languages.vue'),
    meta: {
      resource: 'languages',
      action: 'control',
    },
  },
  {
    path: '/classifications',
    name: 'classifications',
    component: () => import('@/views/general-settings/Classifications.vue'),
    meta: {
      resource: 'classifications',
      action: 'control',
    },
  },
  {
    path: '/subclassifications',
    name: 'sub-classifications',
    component: () => import('@/views/general-settings/SubClassifications.vue'),
    meta: {
      resource: 'sub-classifications',
      action: 'control',
    },
  },
  {
    path: '/donation-categories',
    name: 'donation-categories',
    component: () => import('@/views/general-settings/DonationCategories.vue'),
    meta: {
      resource: 'donation-categories',
      action: 'control',
    },
  },
]

export default [
  {
    path: '/attendance/scanQR',
    name: 'scan-qr',
    component: () => import('@/views/event-attendance/scanQR.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'getBookingDetailsByQRCode',
    },
  },
  {
    path: '/attendance/confirmAttendees/:QRCode',
    name: 'confirm-attendance',
    component: () => import('@/views/event-attendance/confirmAttendance.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'verifyBookingAttendance',
    },
  },
]

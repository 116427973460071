export default [
  {
    path: '/notifications/list',
    name: 'list-notifications',
    component: () => import('@/views/notifications/list.vue'),
    meta: {
      resource: 'notifications',
      action: 'list',
    },
  },
  {
    path: '/notifications/add',
    name: 'add-notifications',
    component: () => import('@/views/notifications/control-notifications/addNotification.vue'),
    meta: {
      resource: 'notifications',
      action: 'add',
    },
  },
]

export default [
  {
    path: '/mobile-users/list',
    name: 'mobile-users-list',
    component: () => import('@/views/mobiles-users/list.vue'),
    meta: {
      resource: 'UsersController',
      action: 'show',
    },
  },
  {
    path: '/mobile-users/view/:id',
    name: 'mobile-users-view',
    component: () => import('@/views/mobiles-users/view.vue'),
    meta: {
      resource: 'UsersController',
      action: 'show',
    },
  },
  {
    path: '/mobile-users/otp',
    name: 'mobile-users-otp',
    component: () => import('@/views/mobiles-users/otp.vue'),
    meta: {
      resource: 'UsersController',
      action: 'getUserDetailsWithOTP',
    },
  },
]

export default [
  {
    subject: 'Auth',
    action: 'read',
  },
  {
    subject: 'Auth',
    action: 'change-password',
  },
  {
    subject: 'Entity',
    action: 'select-entity',
  },
  {
    subject: 'organization',
    action: 'manage',
  },
  {
    subject: 'permissions',
    action: 'manage',
  },
]

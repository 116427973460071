export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/auth/changePassword.vue'),
    meta: {
      resource: 'Auth',
      action: 'change-password',
    },
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    component: () => import('@/views/auth/resetPasswordRequest.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/resetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]

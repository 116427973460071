import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

const intercept = config => {
  const { username } = JSON.parse(localStorage.getItem('userData')) ?? ''
  const token = `Bearer ${localStorage.getItem('accessToken')}`

  config.headers.Authorization = token
  config.headers.username = username
  config.headers.entityId = store.getters['mainEntity/getEntityId']

  return config
}

const http = axios.create({})
const activities = axios.create({
  baseURL: process.env.VUE_APP_ACTIVITIES_BASE_URL,
})
const entities = axios.create({
  baseURL: process.env.VUE_APP_ENTITIES_BASE_URL,
})
const portalUsers = axios.create({
  baseURL: process.env.VUE_APP_USERS_BASE_URL,
  validateStatus(status) {
    return status < 400
  },
})
const payment = axios.create({
  baseURL: process.env.VUE_APP_PAYMENT_BASE_URL,
})
const notification = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATIONS_BASE_URL,
})

http.interceptors.request.use(config => {
  const { username } = JSON.parse(localStorage.getItem('userData')) ?? ''
  const token = `Bearer ${localStorage.getItem('accessToken')}`
  config.headers.Authorization = token
  config.headers.username = username
  const generalEntityId = 1
  config.headers.entityId = generalEntityId

  return config
})
activities.interceptors.request.use(intercept)
entities.interceptors.request.use(intercept)
portalUsers.interceptors.request.use(intercept)
payment.interceptors.request.use(intercept)
notification.interceptors.request.use(intercept)

Vue.prototype.$http = http
Vue.prototype.$activities = activities
Vue.prototype.$portalUsers = portalUsers
Vue.prototype.$entities = entities
Vue.prototype.$payment = payment
Vue.prototype.$notification = notification

export default {
  http, activities, portalUsers, entities, payment, notification,
}

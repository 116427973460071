export default [
  {
    path: '/portal-users/list',
    name: 'portal-users-list',
    component: () => import('@/views/portal-users/list.vue'),
    meta: {
      resource: 'portal-users',
      action: 'list',
    },
  },
  {
    path: '/portal-users/view/:id',
    name: 'portal-user-view',
    component: () => import('@/views/portal-users/view.vue'),
    meta: {
      resource: 'portal-users',
      action: 'view',
    },
  },
]

export default [
  {
    path: '/permissions/portalUsers',
    name: 'portal-user-list',
    component: () => import('@/views/permissions/portalUsers/list.vue'),
    meta: {
      resource: 'permissions',
      action: 'portal-users-list',
    },
  },
  {
    path: '/permissions/roles',
    name: 'roles-list',
    component: () => import('@/views/permissions/roles/list.vue'),
    meta: {
      resource: 'permissions',
      action: 'roles-list',
    },
  },
  {
    path: '/permissions/assignEntityLeader',
    name: 'assign-entity-leader',
    component: () => import('@/views/permissions/portalUsers/control-users/assignLeaderToEntity.vue'),
    meta: {
      resource: 'admin-permissions',
      action: 'assign-entity-leader',
    },
  },
  {
    path: '/permissions/assignEntityMember',
    name: 'assign-entity-member',
    component: () => import('@/views/permissions/portalUsers/control-users/assignMemberToEntity.vue'),
    meta: {
      resource: 'permissions',
      action: 'assign-entity-member',
    },
  },
  {
    path: '/permissions/editUserPermission/:entityId/:username',
    name: 'edit-user-permission',
    component: () => import('@/views/permissions/portalUsers/control-users/editUserPermission.vue'),
    meta: {
      resource: 'permissions',
      action: 'edit-user-permission',
    },
  },
  {
    path: '/permissions/editUserRole/:entityId/:username',
    name: 'edit-user-role',
    component: () => import('@/views/permissions/portalUsers/control-users/editUserRole.vue'),
    meta: {
      resource: 'permissions',
      action: 'edit-user-role',
    },
  },
  {
    path: '/permissions/editRolePermission/:entityId/:roleId',
    name: 'edit-role-permission',
    component: () => import('@/views/permissions/roles/control-roles/editRolePermissions.vue'),
    meta: {
      resource: 'permissions',
      action: 'edit-role-permission',
    },
  },
  {
    path: '/permissions/createRoleInEntity',
    name: 'create-role-in-entity',
    component: () => import('@/views/permissions/roles/control-roles/createRoleInEntity.vue'),
    meta: {
      resource: 'permissions',
      action: 'create-entity-role',
    },
  },
]
